import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout/Layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SEO = makeShortcode("SEO");
const Playlist = makeShortcode("Playlist");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEO title="Music" mdxType="SEO" />
    <h1>{`Music`}</h1>
    <p>{`Music, for me, is a way of expressing emotion. Interestingly it is an art form where we can draw paralells to programming, such as esthetics, simpleness, and rythym. Unfortunately my musical ability trails the programming one.`}</p>
    <Playlist tracks={[{
      name: "Arrivederci",
      year: 2018,
      src: "/music/2018-arrivederci.mp3"
    }, {
      name: "Shining",
      year: 2017,
      src: "/music/2017-shinning.mp3"
    }, {
      name: "Atme",
      year: 2016,
      src: "/music/2016-atme.mp3"
    }, {
      name: "Paixão",
      year: 2013,
      src: "/music/2013-passion.mp3"
    }, {
      name: "Untitled ft. Marco & Sandra",
      year: 2012,
      src: "/music/2012-untitled.mp3"
    }, {
      name: "Somnium",
      year: 2012,
      src: "/music/2012-somnium.mp3"
    }, {
      name: "O Adeus",
      year: 2010,
      src: "/music/2010-o-adeus.mp3"
    }, {
      name: "Limão",
      year: 2009,
      src: "/music/2009-lemon.mp3"
    }]} mdxType="Playlist" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      